import React, {useEffect, useState} from 'react';
import './multiple-select.scss';
import {useTranslation} from "react-i18next";
import {Checkbox, ClickAwayListener} from "@mui/material";

const MultipleSelect = (props: any) => {
    const { placeholder, list, value, setValue } = props;
    const [active, setActive] = useState(false);
    const toggleItem = (item: any) => {
        value.includes(item) ? setValue(value.filter((e: any) => e !== item)) : setValue([...value, item])
    }
    const { t } = useTranslation();
    const handleClickAway = () => {
        return active ?  setActive(false) : null;
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
        <div className={ "multiple__select__container" } >
            <div className={active ? "select__body focused":"select__body"} onClick={() => setActive(!active)}>
                {(value !== undefined && value.length >= 1)
                    ? (
                    <p>
                        {list.filter((e: any) => value.includes(e.value)).map((e:any)=>e.title).join(', ')}
                    </p>
                )
                : (
                    <span>{t(placeholder)}</span>
                    )}
            </div>

                <div className={active ? 'multiple__select__dropdown active' : 'multiple__select__dropdown'}>
                    {list?.map((e: any) => (
                        <div key={e.value} className="select__item" onClick={() => toggleItem(e.value)}>
                            <Checkbox color="success" checked={value.includes(e.value)}
                                   onChange={() => toggleItem(e.value)}/>
                            <p>{e.title}</p>
                        </div>
                    ))}
                </div>
        </div>
        </ClickAwayListener>
    );
};

export default MultipleSelect;
