import React, {useEffect, useState} from 'react';
import './Autocomplete.scss';
import {Checkbox, ClickAwayListener} from "@mui/material";
import {useTranslation} from "react-i18next";

const Autocomplete = (props: any) => {
    const {icon, value, list, setValue, placeholder} = props;
    const { t } = useTranslation()
    const [active, setActive] = useState(false)
    const [__values, set__values] = useState(list)
    const [search, setSearch] = useState(list.find((e: any) => e.value === value) && '');
    const [selected, setSelected] = useState(false)

    const selectItem = (e: any) => {
        setValue(e.value);
        setSelected(true);
        setSearch(e.title)
        setActive(false);
    }

    const handleClickAway = () => {
        setActive(false)
    }

    const handleInput = (event: any) => {
        setSearch(event.currentTarget.value);
        set__values(list.filter((e:any) => e.title.toLowerCase().indexOf(event.currentTarget.value.toLowerCase()) >= 0))
    }

    useEffect(() => {
        const val =list.find((e:any) => e.value == value);
        if (val) selectItem(val);
    }, [value]);



    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={"autocomplete__container"}>
                <span className="icon">
                    {icon !== undefined ? icon : null}
                </span>
                <input type="text" onInput={handleInput} onFocus={() => setActive(true)} onFocusCapture={() => setActive(false)} value={search === undefined ? '': search} placeholder={t(placeholder)}/>
                <div className={active ? "autocomplete__dropdown active" : "autocomplete__dropdown"}>
                    { __values?.map((e: any) => (
                        <div key={e.value} className="dropdown__item" onClick={() => selectItem(e)}>
                            <p>{e.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default Autocomplete;
