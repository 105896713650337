import React from 'react';
import './HeaderRow.scss';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const HeaderRow = (props: any) => {
    const { title, backUrl } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (

        <div className="header__row">
            <div className="back__btn" onClick={() => navigate(-1)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
                    <path
                        d="M0 6C0 6.23675 0.0879369 6.43968 0.277339 6.61556L5.54002 11.77C5.68884 11.9188 5.87824 12 6.10147 12C6.54791 12 6.90643 11.6483 6.90643 11.195C6.90643 10.9718 6.81172 10.7756 6.66291 10.6201L1.92108 6L6.66291 1.37993C6.81172 1.22435 6.90643 1.02142 6.90643 0.804961C6.90643 0.351747 6.54791 0 6.10147 0C5.87824 0 5.68884 0.0811725 5.54002 0.229989L0.277339 5.37768C0.0879369 5.56032 0 5.76325 0 6Z"
                        fill="#656A81"/>
                </svg>
                {t('common.back')}
            </div>
            <div className="header__title">
                {title}
            </div>
        </div>
    );
};

HeaderRow.defaultProps = {
    backUrl: -1,
    title: null,
}

export default HeaderRow;
