import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/styles/app.scss';
import './app/locale/index'
import App from "./app/app";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
   <App/>
  </React.StrictMode>
);

