import React from 'react';
import './CategorySlider.scss';
import {Swiper, SwiperSlide} from "swiper/react";
import {
    Navigation,
    Pagination,
} from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import {useDispatches} from "../../../store/hooks";
import {useNavigate} from "react-router-dom";
import {setFilter} from "../../../store/filter.slice";
const CategorySlider = () => {
    const dispatch = useDispatches();
    const navigate = useNavigate();

    const handleSelect = () => {
        dispatch(setFilter({type: 'categories', payload: [1]}))
        navigate('/filter')
    }

    return (
        <div className="slider__container">
            <Swiper
                modules={[Pagination, Navigation]}
                direction={"horizontal"}
                slidesPerView={1}
                spaceBetween={15}
                style={{marginTop: '45px'}}
                pagination={{
                    clickable: true,
                }}
                loop={true}
                allowTouchMove={true}
            >
                <p className="category__title">
                    Categories
                </p>
                {new Array(3).fill(null).map((item,i) => (
                    <SwiperSlide  key={i}>
                        <div className="category__slide">
                            {new Array(8).fill(null).map((child: any, index: number) => (
                                <div className="category__item" key={index} onClick={() => handleSelect()}>
                                    <div className="image">
                                        <img loading={"lazy"}  src={require('../../../../assets/images/category.png')} alt=""/>
                                    </div>
                                    <p>{['Theatre', 'Cinema', 'Concert'][Math.floor(Math.random() * 3)]}</p>
                                </div>
                            ))}

                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default CategorySlider;
