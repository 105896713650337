import React, {useState} from 'react';
import './Home.scss';
import EventItem from "../../global/components/event-item/event-item";
import CategorySlider from "./CategorySlider/CategorySlider";
import FilterRow from "../../global/components/FilterRow/FilterRow";
import ItemsByCategory from "./ItemsByCategory/ItemsByCategory";

const Home = () => {

    const [topEvents, setTopEvents] = useState([]);
    const [itemsByCategories, setItemsByCategories] = useState([
        {name: 'Drama', id: 1, items: new Array(15).fill(null)},
        {name: 'Thriller', id: 2, items: new Array(15).fill(null)},
        {name: 'Melodrama', id: 3, items: new Array(15).fill(null)},
        {name: 'Fantasy', id: 4, items: new Array(15).fill(null)},
    ])

    return (
        <div className='home__container'>
            <div className="header__container">
                <img className="header_image" src={require('../../../assets/images/header.png')}/>
            </div>
            <div className="top__container">
                {new Array(10).fill(null).map((e, i) => (<EventItem key={i}/>))}
            </div>
            <CategorySlider/>
            <FilterRow redirecting={true}/>
            {itemsByCategories.map((e: any) => (<ItemsByCategory row={e}/>))}
        </div>
    );
};


export default Home;
