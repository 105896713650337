import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/Home/Home";
import EventDetails from "./pages/EventDetails/EventDetails";
import {Provider} from "react-redux";
import store from "./store";
import FilteringPage from "./pages/FilteringPage/FilteringPage";
const router = createBrowserRouter([
    {
        path: '/',
        element: <Home/>
    },
    {
        path: '/event/:id',
        element: <EventDetails/>
    },
    {
        path: '/filter',
        element: <FilteringPage/>
    }
])
const App = () => {

    return (
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
);
};

export default App;
