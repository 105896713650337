import React, {useState} from 'react';
import './ZoomableImage.scss';
import {ClickAwayListener, Modal} from "@mui/material";
import PropTypes from "prop-types";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

const ZoomableImage = (props: any) => {
    const {albumMod, list} = props;
    const [albumIndex, setAlbumIndex] = useState<number>(-1)
    const [open, setOpen] = useState(false)
    const handleSelect = (index: number) => {
        setOpen(true);
        setAlbumIndex(index);
    }
    return (
        <ClickAwayListener onClickAway={() => null}>
            {albumMod
                ? <>
                    <div className="album__row">
                        {list.map((e:any, index: number) => (
                            <img loading={"lazy"} src={require('../../../../assets/images/desc.png')} alt="" onClick={() => handleSelect(index)}/>
                        ))}
                    </div>
                    <Modal open={open}>
                        <div className="zoom__wrapper">
                            <div className={"zoom__content"}>
                                <div className={albumIndex === 0 ? "prev__btn disabled" : "prev__btn"} onClick={() => setAlbumIndex(albumIndex - 1)}>
                                   <ChevronLeft/>

                                </div>
                                <img loading={"lazy"} src={require('../../../../assets/images/desc.png')} alt=""/>
                                <div className={albumIndex === list.length ? "next__btn disabled" : "next__btn"} onClick={() => setAlbumIndex(albumIndex + 1)}>
                                   <ChevronRight/>
                                </div>

                            </div>
                            <div className={"zoom__bg"} onClick={() => setOpen(false)}>

                            </div>
                        </div>
                    </Modal>
                </>
                : <>
                    <div onClick={() => setOpen(true)}>
                        {props.children}
                    </div>
                    <Modal open={open}>
                        <div className="zoom__wrapper">
                            <div className={"zoom__content"}>
                                {props.children}
                            </div>
                            <div className={"zoom__bg"} onClick={() => setOpen(false)}>

                            </div>
                        </div>
                    </Modal>
                </>}
        </ClickAwayListener>
    );
};

ZoomableImage.propTypes = {
    albumMod: PropTypes.bool,
    list: PropTypes.array
}

ZoomableImage.defaultProps = {
    albumMod: false,
}
export default ZoomableImage;
