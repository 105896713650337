import React from 'react';
import './FilteringPage.scss';
import FilterRow from "../../global/components/FilterRow/FilterRow";
import HeaderRow from "../../global/components/HeaderRow/HeaderRow";

const FilteringPage = () => {
    return (
        <div className="filter__page__container">
            <HeaderRow/>
            <FilterRow fullRow={false}/>
        </div>
    );
};

export default FilteringPage;
