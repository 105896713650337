import React, {useState} from 'react';
import './FilterRow.scss';
import {Clear, LocationOn, SearchOutlined, X} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {Drawer} from "@mui/material";
import MultipleSelect from "../../form/multiple-select/multiple-select";
import Autocomplete from "../../form/Autocomplete/Autocomplete";
import DatePicker from "../../form/DatePicker/DatePicker";
import {useSelector} from "react-redux";
import {useDispatches, useStates} from "../../../store/hooks";
import {filterSlice, setFilter} from "../../../store/filter.slice";
import {useNavigate} from "react-router-dom";
import DateRange from "../../form/DateRange/DateRange";

const FilterRow = (props: any) => {
    const { fullRow, redirecting } = props;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false)
    const [geners, setGeners] = useState([
        {value: 1, title: 'Comedy'},
        {value: 2, title: 'Action'},
        {value: 3, title: 'Criminal'},
        {value: 4, title: 'Fantasy'},
        {value: 5, title: 'Horror'},
    ]);
    const [categories, setCategories] = useState([
        {value: 1, title: 'Theatre'},
        {value: 2, title: 'Cinema'},
        {value: 3, title: 'Concert'},
        {value: 4, title: 'Event'},
        {value: 5, title: 'Festival'}
    ])
    const [autocomplete, setAutocomplete] = useState([
        {value: 1, title: 'Sundukyan Theatre'},
        {value: 2, title: 'Cinema Star'},
        {value: 3, title: 'Kinopark'},
        {value: 4, title: 'Moscow Cinema'}
    ])
    const dispatch = useDispatches()
    const filters = useStates((store) => store.filter)
    const changeCategories = (e: []) => {
        dispatch(setFilter({  payload: e, type: 'categories'}))
    }

    const changeGeners = (e: []) => {
        dispatch(setFilter({  payload: e, type: 'geners'}))
    }

    const changeWhere = (e: number | string) => {
        dispatch(setFilter({type: 'where',payload: e}))
    }

    const changeWhen = (e: any) => {
        dispatch(setFilter({  payload: e, type: 'when'}))
    }

    const handleInput = (e:any) => {
        dispatch(setFilter({payload: e.target.value, type: 'search'}))
    }

    const handleFocus = (event: any) => {
        if (redirecting) navigate('/filter')
    }

    return (
        <div className={fullRow ? "filter__row" : "filter__row full__input"} onClick={handleFocus}>
            <div className="filter__search">
                <SearchOutlined/>
                <input type="text" onInput={handleInput} value={filters.search} placeholder={t('common.search')}/>
            </div>
            <div className="filter__btn" onClick={() => setOpenModal(true)}>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                     >
                    <path
                        d="M1.90299 1H16.3508C16.5903 1 16.8199 1.09514 16.9893 1.26448C17.1586 1.43382 17.2538 1.6635 17.2538 1.90299V3.33512C17.2537 3.57459 17.1585 3.80423 16.9892 3.97354L11.1974 9.76529C11.0281 9.9346 10.9329 10.1642 10.9329 10.4037V16.097C10.9329 16.2343 10.9016 16.3697 10.8413 16.4931C10.7811 16.6164 10.6936 16.7244 10.5854 16.8089C10.4772 16.8933 10.3512 16.952 10.2169 16.9804C10.0826 17.0089 9.94359 17.0063 9.81044 16.9729L8.00447 16.5214C7.8092 16.4725 7.63588 16.3598 7.51203 16.2011C7.38818 16.0424 7.32091 15.8468 7.32091 15.6455V10.4037C7.32086 10.1642 7.22569 9.9346 7.05633 9.76529L1.26458 3.97354C1.09522 3.80423 1.00005 3.57459 1 3.33512V1.90299C1 1.6635 1.09514 1.43382 1.26448 1.26448C1.43382 1.09514 1.6635 1 1.90299 1Z"
                        stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{t('common.filter_by')}</span>
            </div>
            <Drawer
                anchor={"right"}
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <div className="filter__drawer">
                    <div className="drawer__exit">
                        <Clear onClick={() => setOpenModal(false)}/>
                    </div>
                    <div className="drawer__title">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                             onClick={() => setOpenModal(true)}>
                            <path
                                d="M1.90299 1H16.3508C16.5903 1 16.8199 1.09514 16.9893 1.26448C17.1586 1.43382 17.2538 1.6635 17.2538 1.90299V3.33512C17.2537 3.57459 17.1585 3.80423 16.9892 3.97354L11.1974 9.76529C11.0281 9.9346 10.9329 10.1642 10.9329 10.4037V16.097C10.9329 16.2343 10.9016 16.3697 10.8413 16.4931C10.7811 16.6164 10.6936 16.7244 10.5854 16.8089C10.4772 16.8933 10.3512 16.952 10.2169 16.9804C10.0826 17.0089 9.94359 17.0063 9.81044 16.9729L8.00447 16.5214C7.8092 16.4725 7.63588 16.3598 7.51203 16.2011C7.38818 16.0424 7.32091 15.8468 7.32091 15.6455V10.4037C7.32086 10.1642 7.22569 9.9346 7.05633 9.76529L1.26458 3.97354C1.09522 3.80423 1.00005 3.57459 1 3.33512V1.90299C1 1.6635 1.09514 1.43382 1.26448 1.26448C1.43382 1.09514 1.6635 1 1.90299 1Z"
                                stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <p>
                            {t('common.filter_by')}
                        </p>
                    </div>
                    <div className="drawer__filters">
                        <Autocomplete
                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14"
                                       viewBox="0 0 12 14" fill="none">
                                <path
                                    d="M6.00051 7.50065C6.50089 7.50061 6.94166 7.31642 7.29588 6.9622C7.65006 6.60802 7.83416 6.16736 7.83366 5.66713M6.00051 7.50065C5.50028 7.50115 5.05962 7.31705 4.70544 6.96287C4.35122 6.60865 4.16704 6.16788 4.16699 5.6675M6.00051 7.50065C6.00045 7.50065 6.00039 7.50065 6.00033 7.50065V7.00065L6.00051 7.50065ZM4.16699 5.6675C4.16649 5.16727 4.35059 4.72661 4.70477 4.37243C5.05899 4.01821 5.49976 3.83403 6.00014 3.83398M4.16699 5.6675C4.16699 5.66744 4.16699 5.66738 4.16699 5.66732H4.66699L4.16699 5.66792C4.16699 5.66778 4.16699 5.66764 4.16699 5.6675ZM6.00014 3.83398C6.50037 3.83348 6.94103 4.01758 7.29521 4.37176C7.64943 4.72598 7.83361 5.16675 7.83366 5.66713M6.00014 3.83398C6.0002 3.83398 6.00026 3.83398 6.00033 3.83398V4.33398L5.99972 3.83398C5.99986 3.83398 6 3.83398 6.00014 3.83398ZM7.83366 5.66713C7.83366 5.66719 7.83366 5.66726 7.83366 5.66732H7.33366L7.83366 5.66671C7.83366 5.66685 7.83366 5.66699 7.83366 5.66713ZM10.8337 5.80052V5.80065C10.8337 6.74046 10.481 7.79354 9.71472 8.96972C8.94908 10.145 7.7756 11.4397 6.17624 12.8531C6.15682 12.8686 6.12998 12.8847 6.09143 12.8992C6.05483 12.9129 6.02528 12.9173 6.00033 12.9173C5.97537 12.9173 5.94582 12.9129 5.90922 12.8992C5.87068 12.8847 5.84384 12.8686 5.82443 12.8531C4.22507 11.4393 3.05158 10.1443 2.28593 8.96906C1.51967 7.79284 1.16699 6.73998 1.16699 5.80065C1.16699 4.25172 1.65989 3.06523 2.6146 2.1849C3.59818 1.27795 4.71991 0.833984 6.00033 0.833984C7.28123 0.833984 8.40317 1.27798 9.38671 2.1849C10.3414 3.0652 10.8341 4.25162 10.8337 5.80052Z"
                                    stroke="#B1B1B1"/>
                            </svg>}
                            value={filters.where} list={autocomplete} placeholder={"filters.where"} setValue={changeWhere}/>
                        <DateRange placeholder={"filters.when"} setValue={changeWhen} value={filters.when}/>
                        <MultipleSelect list={categories} value={filters.categories} setValue={changeCategories}
                                        placeholder={'filters.categories'}/>
                        <MultipleSelect list={geners} value={filters.geners} setValue={changeGeners}
                                        placeholder={'filters.geners'}/>
                    </div>
                </div>


            </Drawer>
        </div>
    );
};


FilterRow.defaultProps  = {
    fullRow: true,
    redirecting: false,
}
export default FilterRow;
