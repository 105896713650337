import React from 'react';
import './ItemsByCategory.scss';
import {useTranslation} from "react-i18next";
import EventItem from "../../../global/components/event-item/event-item";
import {useDispatches} from "../../../store/hooks";
import {useNavigate} from "react-router-dom";
import {setFilter} from "../../../store/filter.slice";

const ItemsByCategory = (props: any) => {
    const { row: { items, name, id} } = props;
    const navigate = useNavigate();
    const dispatch = useDispatches()
    const { t } = useTranslation();


    const handleSelect = () => {
        dispatch(setFilter({type: 'geners', payload: [id]}));
        navigate('/filter')
    }

    return (
        <div className="items__by__category">
            <div className="title__row">
                <h2>{name}</h2>
                <span onClick={handleSelect}>
                    {t('common.see_more')}
                </span>
            </div>
            <div className="top__container">
                {items.map((e: any, index: number) => (<EventItem key={index}/>))}
            </div>
        </div>
    );
};

export default ItemsByCategory;
