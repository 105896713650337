import {configureStore} from "@reduxjs/toolkit";
import {filterSlice} from "./filter.slice";
const store = configureStore({
    reducer: {
        filter: filterSlice.reducer
    }
});
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;