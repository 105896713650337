import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export const filterSlice = createSlice({
    name: 'filter',
    initialState: {
        categories: [],
        geners: [],
        where: undefined,
        when: undefined,
        search: '',
    },
    reducers: {
        setFilter(state, action) {
           // @ts-ignore
            state[action.payload.type] = action.payload.payload
        }
    }
})

export const  { setFilter } = filterSlice.actions;
