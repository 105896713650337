import React, {useState} from 'react';
import './EventDetails.scss';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import HeaderRow from "../../global/components/HeaderRow/HeaderRow";
import ZoomableImage from "../../global/components/ZoomableImage/ZoomableImage";

const EventDetails = () => {
    const { t } = useTranslation();
    const [days, setDays] = useState([
        {date: moment().add(1, 'day'),id: 1},
        {date: moment().add(2, 'day'),id: 2},
        {date: moment().add(3, 'day'),id: 3},
        {date: moment().add(4, 'day'),id: 4}
    ])
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null)
    const [alubmList, setAlubmList] = useState(new Array(6).fill(null))
    const navigate = useNavigate()

    const handleSelect = (e: any) => {
        if (selectedDate !== null && selectedDate.isSame(e.date)) {
            return setSelectedDate(null);
        }
        return setSelectedDate(e.date);

    }

    return (
        <div className="event__details">
            <HeaderRow title={"Cocktail Party"} />
            <div className="about__container">
                <ZoomableImage>
                    <img src={require('../../../assets/images/card_example.png')} alt=""/>
                </ZoomableImage>
                <div className="details__column">
                <div className="category">
                        <p>Theatre</p>
                        <span>18+</span>
                    </div>
                    <div className="geners">
                        {['Comedy','Melodrama'].join(', ')}
                    </div>
                    <div className="durition">
                        180 {t('common.mins')}
                    </div>
                    <div className="trailer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path
                                d="M7.83586 4.73665L10.1499 2.42265C11.0429 1.52999 12.6629 1.70199 13.5555 2.59499C14.4482 3.48799 14.6205 5.10765 13.7279 6.00065L10.3002 9.42832C9.4072 10.321 7.78753 10.149 6.89453 9.25599"
                                stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M8.46493 11.2624L6.15093 13.5768C5.25793 14.4694 3.63793 14.2974 2.74526 13.4044C1.8526 12.5114 1.68026 10.8918 2.57293 9.99876L6.0006 6.57109C6.8936 5.67842 8.51326 5.85042 9.40626 6.74342"
                                stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>
                            {t('common.trailer')}
                        </span>
                    </div>
                </div>
            </div>
            <ZoomableImage
                list={alubmList}
                albumMod={true}
            />
            <div className="description__container">
                <p className="description__title">{t('common.description')}</p>
                <p>Lorem ipsum dolor sit amet consectetur. Duis ut massa fringilla in nunc sollicitudin integer mauris. Amet augue risus bibendum congue. Neque lacus vulputate tellus faucibus diam vehicula id eros. Ut cursus bibendum ut ac sed lectus nulla.</p>
                <p>2000-15000 AMD</p>
            </div>
            <div className="days__row">
                {days.map((e:any) => (
                    <div className={e.date.isSame(selectedDate) ? "day__item selected" : "day__item"} key={e.id} onClick={() => handleSelect(e)}>
                        <p>{t('date.months.full_' + e.date.format('MM')).substring(0,3)}

                            {e.date.format(' DD, H:mm')}</p>
                    </div>
                ))}
            </div>
            <div className="btn__row">
                <button className={selectedDate !== null ? '' : 'disabled'}>
                    {t('common.buy_ticket')}
                </button>
            </div>
        </div>
    );
};

export default EventDetails;
