import React from 'react';
import { useNavigate } from 'react-router-dom';
import './event-item.scss';

const EventItem = () => {
    const navigate = useNavigate();

    return (
        <div className="event__item" onClick={() => navigate('/event/1')}>
            <div className="event__item__image">
                <img loading={"lazy"}  src={require('../../../../assets/images/card_example.png')} alt=""/>
            </div>
            <div className="event__item__body">
                <p>Cocktail Party</p>
                <span>Bar</span>
            </div>
        </div>
    );
};

export default EventItem;
